import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Disclosure, Popover, Transition } from '@headlessui/react'
import { navigate } from "gatsby"

import { SearchIcon } from '@heroicons/react/solid'
import ShareButtons from "./sharebuttons"
import SponsorLink from "../promotions/sponsorLink"
import {
  BellIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'

import withLocation from "./withlocation"
import Image from "./image"
import Navigation from "./navigation"


const navigation = [
  // { name: 'About', href: '/about/' },
  { name: 'Topics', href: '/topics/', 'activeNav': '/category/' },
  { name: 'Communities', href: '/communities/' },
  { name: 'Add Community', href: '/submit/', button: false },
  { name: 'Advertise', href: '/partners/community/', 'activeNav': '/partners/' },
  { name: 'More', nested: true, links: [
    { name: 'Platforms', href: '/platforms/' },
    { name: 'Features', href: '/features/' },
    { name: 'Tools', href: '/tools/' },
    { name: 'Blog', href: '/blog/' },
    // { name: 'Traffic', href: '/partners/traffic/' },
  ]},
  // { name: 'Add Community', href: '/submit/', button: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Header = ({ pageTitle, expanded, headerImage, subtitle, location, enableShare }) => {

  const isMobile = (typeof window !== 'undefined') && window.screen.width < 600;

  const submitSearch = (event) => {
    event.preventDefault();
    const value = document.getElementById('searchfield').value;

    // TODO: do in react way so that we don't refresh whole page
    if (value && value !== ""){
      navigate(`/search/?keyword=${encodeURIComponent(value)}`)
    }
  }

  return (
    <Disclosure as="div" className="relative bg-yellow-300 pb-28 sm:pb-32 overflow-hidden">
      {({ open }) => (
        <>
          <nav
            className={classNames(
              'relative z-10 border-b border-yellow-500 border-opacity-25 lg:bg-transparent lg:border-none'
            )}
          >
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
              <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-yellow-400">
                <div className="px-2 flex items-center lg:px-0">
                  <Link to="/" className="flex-shrink-0">
                    {/*<img
                      className="block h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=rose&shade=500"
                      alt="Workflow"
                    />*/}
                    <Image className="block h-10 w-auto" fileName="icon.png" alt="Hive Index Logo" height="35" width="31" /> 
                  </Link>
                  <div className="hidden lg:block lg:ml-6 lg:space-x-4">
                    <Navigation navigation={navigation} />
                  </div>
                </div>
                <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                  <div className="max-w-lg w-full lg:max-w-lg">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative text-yellow-800 focus-within:text-gray-400">
                      <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                        <SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                      </div>
                      <form onSubmit={submitSearch}>
                        <input
                          id="searchfield"
                          name="search"
                          className="block w-full bg-yellow-200 text-gray-800 bg-opacity-80 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 placeholder-yellow-800 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                          placeholder="Search topics & communities"
                          type="search"
                          autoComplete="off"
                          spellCheck="off"
                        />
                      </form>
                      
                    </div>
                  </div>
                </div>
                <div className="flex lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="p-2 rounded-md inline-flex items-center justify-center text-yellow-500 hover:text-white hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block flex-shrink-0 h-8 w-8" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block flex-shrink-0 h-8 w-8" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="bg-yellow-300 lg:hidden">
              <div className="pt-2 pb-3 px-2 space-y-1">
                {navigation.map((item) => (
                  <React.Fragment key={item.name}>
                    {!item.nested ? (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          location.pathname.startsWith(item.href) ? 'bg-yellow-400' : 'hover:bg-yellow-400',
                          'block rounded-md py-2 px-3 text-base font-medium text-gray-800'
                        )}
                      >
                        {item.name}
                      </Link>
                    ) : (
                      <React.Fragment>
                        {item.links.map((l) => (
                          <Link
                            key={l.name}
                            to={l.href}
                            className={classNames(
                              location.pathname.startsWith(l.href) ? 'bg-yellow-400' : 'hover:bg-yellow-400',
                              'block rounded-md py-2 px-3 text-base font-medium text-gray-800'
                            )}
                          >
                            {l.name}
                          </Link>
                        ))}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </div>
              

            </Disclosure.Panel>
          </nav>
          <div
            aria-hidden="true"
            className={classNames(
              open ? 'bottom-0' : 'inset-y-0',
              'absolute inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0'
            )}
          >
            <div className="absolute inset-0 flex opacity-0">
              <div className="h-full w-1/2 bg-yellow-400" />
              <div className="h-full w-1/2 bg-yellow-500"/>
            </div>
            <div className="relative flex justify-center opacity-50">
              <svg
                className="flex-shrink-0"
                width={1750}
                height={308}
                viewBox="0 0 1750 308"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" className="fill-current text-yellow-300" fill="#0369a1" />
                <path d="M1465.84 308L16.816 0H1750v308h-284.16z" className="fill-current text-yellow-400" fill="#065d8c" />
                <path d="M1733.19 0L284.161 308H0V0h1733.19z" className="fill-current text-yellow-400" fill="#0a527b" />
                <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" className="fill-current text-yellow-300" fill="#0a4f76" />
              </svg>
            </div>
          </div>

          <header className="relative py-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

              {/* Optional sponsorship link. Only shows if active sponsor for the site (but leave space even if it's not there for CLS purposes) */}
              <div className="h-16 mb-3 sm:h-12 sm:mb-0">
                {/* googleoff: all */}
                <SponsorLink location={'header'} canHide={true}>
                  {({ name, url, descriptor, onClick, onHide, cta }) => (
                    <div className="z-40 mx-auto py-1 sm:py-2 px-2 sm:px-3 mb-6 rounded-md bg-yellow-500 text-white font-semibold flex items-center">
                      {onHide ? (
                        <div className="mr-2 opacity-50 hover:opacity-75 cursor-pointer hidden sm:block"
                          onClick={onHide}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                      ) : ''}
                      <div>{isMobile ? 'Sponsored by' : 'Hive Index is brought to you by'} <a href={url} className="underline" onClick={onClick} target="_blank" rel="sponsored nofollow">
                        {name} - {descriptor}</a>
                      </div>
                      <a href={url} className="ml-auto hover:underline hidden sm:flex items-center" onClick={onClick} target="_blank" rel="sponsored nofollow">
                        {cta || 'Learn More'} 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-1">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                      </a>
                    </div>
                  )}
                </SponsorLink>
                {/* googleon: all */}
              </div>

              <div className="flex flex-wrap items-center">
                <h1 className="text-2xl sm:text-4xl font-bold text-gray-800">
                  {pageTitle}
                </h1>
                {enableShare ? (
                  <div className="sm:mt-4 sm:mt-0 w-auto ml-auto">
                    <ShareButtons title={pageTitle} location={location} />
                  </div>
                ) : ''}
              </div>
              {subtitle ? (
                <div className="text-lg font-semibold mt-1 text-gray-800">{subtitle}</div>
              ) : ''}
            </div>
          </header>
        </>
      )}
    </Disclosure>
  );
}

export default withLocation(Header)
