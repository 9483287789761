/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
// import FullStory from 'react-fullstory';

import Header from "./header"
import Footer from "./footer"

import "../../styles/base.css"

const Layout = ({ children, pageTitle, noBackground, subtitle, enableShare, hideBlogPosts }) => {
  
  return (
    <div className="bg-gray-100">
      {/*<FullStory org={'14XCBT'} />*/}
      <Header pageTitle={pageTitle} subtitle={subtitle} enableShare={enableShare} />

      <main className="relative -mt-32">
        <div className="max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">

          {noBackground ? children : (
            <div className="bg-white rounded-lg shadow overflow-hidden p-4 sm:p-8">
              {children}
            </div>
          )}
        </div>
      </main>

      <Footer/>
    </div>
  )
}

export default Layout
